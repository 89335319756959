import showAlertSnackBarConfirmation from './showAPAlertSnackBar';

type Severity = 'positive' | 'negative' | 'info' | 'warning';

/**
 * @deprecated Use `showAlertSnackBarConfirmation` instead
 */
export async function showSnackbar(content: string, severity: Severity = "positive") {
    return showAlertSnackBarConfirmation(content, {
        intent: severity,
        emphasis: 'intense'
    });

    // var close = showDialog(
    //     <Snackbar
    //         anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //         }}
    //         open={true}
    //         autoHideDuration={3000}
    //     >
    //         <Alert
    //             severity={newSeverity}
    //             onClose={() => close()}
    //             iconMapping={{
    //                 error: <InternalSVGImage name="error-snackbar" />,
    //                 success: <InternalSVGImage name="check-snackbar" />,
    //                 info: <InternalSVGImage name="info-snackbar" />,
    //                 warning: <InternalSVGImage name="warning-snackbar" />
    //             }}
    //         >
    //             {content}
    //         </Alert>
    //     </Snackbar>
    // );
    // await sleep(3000);
    // close();
}

export function InternalSVGImage({ name, size = 20, style }: { name: string, size?: number, style?: React.CSSProperties }) {
    return (
        <img src={`/icons/icon-${name}.svg`} alt="" height={size} width={size} style={style} />
    )
}