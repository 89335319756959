import { APImage, APText } from '../../elements';
import { APColumn, APRow } from '../../layout';
import { APDropdownFilter, APDropdownFilterProps } from './APDropdownFilter';
import { useState } from 'react';
import { APPalette } from '../../utils';

interface SelectedChip {
    /**
     * selected option label
     */
    label: string,
    /**
     * selected option array
     */
    value: string[]
}

function convertToArray(value?: string | string[]) {
    if (!value) {
        return [];
    }

    if (Array.isArray(value)) {
        return value;
    }

    return [value];
}

export default function APGroupedDropdownFilter({ options, }: { options: APDropdownFilterProps[], }) {
    const [allOptions, setAllOptions] = useState<APDropdownFilterProps[]>(options)
    const [selectedValues, setSelectedValues] = useState<SelectedChip[]>(options.map((item) => ({ label: item.label, value: convertToArray(item.initialValue) })));
    const [counter, setCounter] = useState(0);

    const allLabelValues = allOptions.flatMap((item) => item.options);

    return (
        <APColumn crossAxisAlignment='stretch' gap='10px'>
            <APRow style={{ flexWrap: 'wrap' }} gap="16px">
                {
                    allOptions.map((item, index) => (
                        <APDropdownFilter
                            key={`${counter}_${index}_grouped_dropdown_filter`}
                            {...item}
                            onChange={(v: any) => {
                                item.onChange(v);
                                if (item.type === 'single') {
                                    selectedValues[index].value = [];
                                    if (v) {
                                        selectedValues[index].value = [v];
                                    }
                                    setSelectedValues([...selectedValues]);
                                    allOptions[index].initialValue = v;
                                    setAllOptions([...allOptions])
                                } else {
                                    selectedValues[index].value = v;
                                    setSelectedValues([...selectedValues]);
                                    allOptions[index].initialValue = v;
                                    setAllOptions([...allOptions])
                                }
                            }}
                        />
                    ))
                }
            </APRow>
            {
                selectedValues.length > 0 &&
                <APRow style={{ flexDirection: "row", flexWrap: "wrap", gap: "8px" }} >
                    {
                        selectedValues.flatMap((e, i) => e.value.map(v => ({ value: v, originalIndex: i }))).map((item, index) => (
                            <ValueChip
                                key={`${counter}_${index}_chip`}
                                value={allLabelValues.find((v) => v.value === item.value)?.label ?? item.value}
                                onRemove={() => {
                                    allOptions[item.originalIndex].initialValue = selectedValues[item.originalIndex].value.filter((v) => v !== item.value);
                                    selectedValues[item.originalIndex].value = selectedValues[item.originalIndex].value.filter((v) => v !== item.value);
                                    setAllOptions([...allOptions]);
                                    setSelectedValues([...selectedValues]);
                                    setCounter(counter + 1);
                                }}
                            />
                        ))
                    }
                </APRow>
            }
        </APColumn>
    )
}

function ValueChip({ value, onRemove }: { value: string, onRemove: () => void }) {
    return (
        <APRow mainAxisSize='min' gap="4px" style={{ borderRadius: "8px", padding: "4px 8px", backgroundColor: APPalette['grey-200'] }}>
            <APText variant='paragraph-small'> {value} </APText>
            <APImage src="/icons/icon-remove.svg" onClick={onRemove} />
        </APRow>
    )
}