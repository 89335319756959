// DO NOT MODIFY BY HAND!!
// Go through Readme.md

import React from 'react';

import { ReactComponent as CartEmptyDark } from './illustration-cartEmptyDark.svg';
import { ReactComponent as CartEmptyLight } from './illustration-cartEmptyLight.svg';
import { ReactComponent as FixedDepositDark } from './illustration-fixedDepositDark.svg';
import { ReactComponent as FixedDepositLight } from './illustration-fixedDepositLight.svg';
import { ReactComponent as InsuranceDark } from './illustration-insuranceDark.svg';
import { ReactComponent as InsuranceLight } from './illustration-insuranceLight.svg';
import { ReactComponent as MutualFundDark } from './illustration-mutualFundDark.svg';
import { ReactComponent as MutualFundLight } from './illustration-mutualFundLight.svg';
import { ReactComponent as NoDocumentsDark } from './illustration-noDocumentsDark.svg';
import { ReactComponent as NoDocumentsLight } from './illustration-noDocumentsLight.svg';
import { ReactComponent as NoInternetDark } from './illustration-noInternetDark.svg';
import { ReactComponent as NoInternetLight } from './illustration-noInternetLight.svg';
import { ReactComponent as NoPendingActionsDark } from './illustration-noPendingActionsDark.svg';
import { ReactComponent as NoPendingActionsLight } from './illustration-noPendingActionsLight.svg';
import { ReactComponent as NoRecordsDark } from './illustration-noRecordsDark.svg';
import { ReactComponent as NoRecordsLight } from './illustration-noRecordsLight.svg';
import { ReactComponent as NoResultsDark } from './illustration-noResultsDark.svg';
import { ReactComponent as NoResultsLight } from './illustration-noResultsLight.svg';
import { ReactComponent as NotFoundDark } from './illustration-notFoundDark.svg';
import { ReactComponent as NotFoundLight } from './illustration-notFoundLight.svg';
import { ReactComponent as NpsDark } from './illustration-npsDark.svg';
import { ReactComponent as NpsLight } from './illustration-npsLight.svg';
import { ReactComponent as PortfolioEmptyDark } from './illustration-portfolioEmptyDark.svg';
import { ReactComponent as PortfolioEmptyLight } from './illustration-portfolioEmptyLight.svg';
import { ReactComponent as WatchlistEmptyDark } from './illustration-watchlistEmptyDark.svg';
import { ReactComponent as WatchlistEmptyLight } from './illustration-watchlistEmptyLight.svg';

export type IllustrationSVG = "cartEmptyDark" | "cartEmptyLight" | "fixedDepositDark" | "fixedDepositLight" | "insuranceDark" | "insuranceLight" | "mutualFundDark" | "mutualFundLight" | "noDocumentsDark" | "noDocumentsLight" | "noInternetDark" | "noInternetLight" | "noPendingActionsDark" | "noPendingActionsLight" | "noRecordsDark" | "noRecordsLight" | "noResultsDark" | "noResultsLight" | "notFoundDark" | "notFoundLight" | "npsDark" | "npsLight" | "portfolioEmptyDark" | "portfolioEmptyLight" | "watchlistEmptyDark" | "watchlistEmptyLight";


export const IllustrationSVGNames = ["cartEmptyDark", "cartEmptyLight", "fixedDepositDark", "fixedDepositLight", "insuranceDark", "insuranceLight", "mutualFundDark", "mutualFundLight", "noDocumentsDark", "noDocumentsLight", "noInternetDark", "noInternetLight", "noPendingActionsDark", "noPendingActionsLight", "noRecordsDark", "noRecordsLight", "noResultsDark", "noResultsLight", "notFoundDark", "notFoundLight", "npsDark", "npsLight", "portfolioEmptyDark", "portfolioEmptyLight", "watchlistEmptyDark", "watchlistEmptyLight"];

function getIcon(icon: IllustrationSVG) {
    switch (icon) {
   case "cartEmptyDark": return <CartEmptyDark />;
   case "cartEmptyLight": return <CartEmptyLight />;
   case "fixedDepositDark": return <FixedDepositDark />;
   case "fixedDepositLight": return <FixedDepositLight />;
   case "insuranceDark": return <InsuranceDark />;
   case "insuranceLight": return <InsuranceLight />;
   case "mutualFundDark": return <MutualFundDark />;
   case "mutualFundLight": return <MutualFundLight />;
   case "noDocumentsDark": return <NoDocumentsDark />;
   case "noDocumentsLight": return <NoDocumentsLight />;
   case "noInternetDark": return <NoInternetDark />;
   case "noInternetLight": return <NoInternetLight />;
   case "noPendingActionsDark": return <NoPendingActionsDark />;
   case "noPendingActionsLight": return <NoPendingActionsLight />;
   case "noRecordsDark": return <NoRecordsDark />;
   case "noRecordsLight": return <NoRecordsLight />;
   case "noResultsDark": return <NoResultsDark />;
   case "noResultsLight": return <NoResultsLight />;
   case "notFoundDark": return <NotFoundDark />;
   case "notFoundLight": return <NotFoundLight />;
   case "npsDark": return <NpsDark />;
   case "npsLight": return <NpsLight />;
   case "portfolioEmptyDark": return <PortfolioEmptyDark />;
   case "portfolioEmptyLight": return <PortfolioEmptyLight />;
   case "watchlistEmptyDark": return <WatchlistEmptyDark />;
   case "watchlistEmptyLight": return <WatchlistEmptyLight />;
default:
            return <div></div>
    }
}

interface APIllustrationProps {
    icon: IllustrationSVG,
    /**
     * @default 240
     */
    size?: number,
}

export function APIllustration({ icon, size = 240 }: APIllustrationProps) {
    return React.cloneElement(getIcon(icon), { width: size, height: size })
}
