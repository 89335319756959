import moment from "moment";
import { useState, useEffect } from "react";
import { APColumn, APStack, APAlign, APRow, APSizedBox, APExpanded, APPadding } from "../layout";
import { APPalette, NArray } from "../utils";

import { APImage } from "./APImage";
import axios from 'axios';

import { APText } from "./APText";
import { APCard } from "./APCard";
import { APIcon } from "../icons/APIcon";
import { APAsyncButton } from "./APAsyncButton";
import { APTag } from "./APTag";
import { showSnackbar } from "../dialogs";

type APMediaCardCategory = 'Research Exclusive' | 'Investment Products' | 'Business Development' | 'Insurance' | 'MutualFunds' | string;

export interface APMediaCardProps {
    /**
     *  Title of the poster or article in max 2 lines
     */
    title: string;
    /**
     *  Created date of the poster or article
     */
    createdAt: Date;
    /**
     *  Number of views of the poster or article
     */
    views?: number;
    /**
     * Poster or article image url
     */
    imgUrl?: string;
    /**
     * In case of youtube video, with help of the videoId the duration of the video and thumbnail is shown
     * In the case of the strapi video, pass the empty string you will get the play icon
     */
    videoId?: String;
    /**
    * If true, "New" tag is shown on the image
    */
    isNew?: boolean;
    /**
     * If true, "Popular" tag is shown on the image
     */
    isPopular?: boolean;
    onClick?: () => void | Promise<void>;
    /**
     * Article author name
     */
    author?: string;
    /**
     *  Category of article
     */
    category?: APMediaCardCategory;
    categoryImgUrl?: string;
    /**
     * @default "default"
     * This changes how the Media Card looks like
     * 
     * @description
     * `article` has 16:9 image aspect ratio
     */
    type?: 'poster' | 'article' | 'default' | 'event' | 'eventChannelPlus';
    /**
     * Provides the respective hashtag on click
     */
    onHashtagClick?: (hashtag: string) => void | Promise<void>;
    /**
     * Max 5 hashtags and 2 lines are shown for the article
     */
    hashtags?: string[];

    webStoryCount?: number;
    // TODO:Review once before pushing
    language?: string;
    location?: string;
    productTag?: string;
    /**
     * If true, the poster will fit the parent container
     */
    fit?: boolean
    isRecent?: boolean;
    isOnline?: boolean;
    eventCategory?: string;
    isRegistered?: boolean;
    eventDate?: Date;
    description?: string;
    onRegisterClick?: () => void | Promise<void>;
    isUpcoming?: boolean
    onJoinClick?: () => void | Promise<void>;
    onShareClick?: () => void | Promise<void>;

}

/**
 * @author `Ibtisham Mansuri`
 * @param `APMediaCardProps`
 * @exports APMediaCard
 */
export default function APMediaCard({ title, createdAt, views, imgUrl = '/images/default_poster.png', videoId, isNew, isPopular, onClick, category, author, onHashtagClick, hashtags, type = 'default', webStoryCount, categoryImgUrl, language, location, isRecent, isOnline, eventCategory, isRegistered, eventDate, description, onRegisterClick, isUpcoming, onJoinClick, onShareClick }: APMediaCardProps) {
    const [duration, setDuration] = useState('');

    async function getVideoDuration() {
        try {
            if (videoId?.includes('https:')) {
                const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([\w-]{11})(?:\S+)?$/;

                const match = videoId?.match(pattern);
                if (match && match[1]) {
                    var id = match[1]
                    videoId = id;
                }
            }

            const response = await axios.get(
                `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${videoId}&key=AIzaSyDGbqlIAnXVEmVD23AFLqo54wcTshHbk6o`
            );
            const hoursRegex = /(\d+)H/;
            const minutesRegex = /(\d+)M/;
            const secondsRegex = /(\d+)S/;
            const d = response.data.items[0].contentDetails.duration;
            const hours = hoursRegex.test(d) ? parseInt(d.match(hoursRegex)[1]) : 0;
            const minutes = minutesRegex.test(d) ? parseInt(d.match(minutesRegex)[1]) : 0;
            const seconds = secondsRegex.test(d) ? parseInt(d.match(secondsRegex)[1]) : 0;
            var time = `${hours < 1 ? '' : hours <= 9 ? '0' + hours + ':' : hours + ':'}${minutes <= 9 ? '0' + minutes : minutes}:${seconds <= 9 ? '0' + seconds : seconds}`
            setDuration(time)
        } catch (error) {
            console.error('Error fetching video duration:', error);
        }
    }

    if (videoId) {
        if (videoId.includes('https:')) {
            const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([\w-]{11})(?:\S+)?$/;

            const match = videoId?.match(pattern);
            if (match && match[1]) {
                var id = match[1]
                imgUrl = `https://img.youtube.com/vi/${id}/0.jpg`
            }
        } else {
            imgUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`
        }
    }

    useEffect(() => {
        if (videoId) {
            getVideoDuration()
        }

    }, [videoId])

    hashtags = hashtags?.slice(0, 5);

    if (author) {
        author = 'By ' + author;
    }

    switch (type) {
        case 'poster':
            return <MediaCardPoster title={title} createdAt={createdAt} views={views ?? 0} imgUrl={imgUrl} videoId={videoId} duration={duration} isNew={isNew} isPopular={isPopular} onClick={onClick} webStoryCount={webStoryCount} />
        case 'article':
            return <MediaCardArticle category={category} title={title} createdAt={createdAt} views={views ?? 0} imgUrl={imgUrl} videoId={videoId} author={author} duration={duration} hashtags={hashtags} onHashtagClick={onHashtagClick} isNew={isNew} isPopular={isPopular} onClick={onClick} categoryImgUrl={categoryImgUrl} />
        case "event":
            return <MediaCardEvent onShareClick={onShareClick} onRegisterClick={onRegisterClick} title={title} language={language} location={location} isRecent={isRecent} isOnline={isOnline} eventCategory={eventCategory ?? ''} isRegistered={isRegistered} eventDate={eventDate} imgUrl={imgUrl} isUpcoming={isUpcoming} />
        case 'default':
            return <MediaCardDefault title={title} createdAt={createdAt} views={views ?? 0} imgUrl={imgUrl} videoId={videoId} duration={duration} author={author} onClick={onClick} />
        case "eventChannelPlus":
            return <MediaCardEventChannelPlus title={title} language={language} videoId={videoId} duration={duration} location={location} eventDate={eventDate} isRecent={isRecent} eventCategory={eventCategory ?? ''} imgUrl={imgUrl} views={views} onClick={onClick} />
        default:
            return null
    }
}

export function formatNumber(number: number): string {
    if (Math.abs(number) >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else if (Math.abs(number) >= 1000) {
        return (number / 1000).toFixed(1) + 'T';
    } else {
        return number.toString();
    }
}

export function Tag({ label }: { label: string }) {
    return (
        <div className='imageCardTag' style={{ backgroundColor: `${label == 'New' || label == 'Recent' ? APPalette['positive-300'] : APPalette['negative-300']}`, borderRadius: '4px 4px 0px 4px' }} >
            <APPadding padding={'2px 6px 2px 6px'}>
                <APText variant='paragraph-xsmall' color="white" >{label}</APText>
            </APPadding>
        </div>
    )
}

function VideoDuration({ time, type }: { time?: string, type?: string }) {
    return (
        <APPadding padding={'4px'} style={{ backgroundColor: 'white', borderRadius: '4px' }}>
            <APRow mainAxisSize="min">
                <APImage src={'/icons/icon-youtube.svg'} />
                {
                    time &&
                    <APText variant={`${type == 'default' ? 'paragraph-xsmall' : 'paragraph-small'}`} color={APPalette['grey-500']}>
                        {time}
                    </APText>
                }

            </APRow>
        </APPadding>
    )
}

function MediaCardArticle({ title, createdAt, views, imgUrl, videoId, isNew, isPopular, category, author, hashtags, duration, categoryImgUrl, onHashtagClick, onClick, }: {
    title: string,
    createdAt: Date,
    views: number;
    imgUrl?: string;
    videoId?: String;
    isNew?: boolean;
    isPopular?: boolean;
    hashtags?: string[];
    onHashtagClick?: (hashtag: string) => void | Promise<void>,
    author?: string;
    category?: APMediaCardCategory;
    duration?: string;
    onClick?: () => void | Promise<void>
    categoryImgUrl?: string;
}) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <APCard
            width="220px"
            padding="0"
            style={{
                borderRadius: '8px',
                cursor: onClick ? 'pointer' : 'auto',
                transition: 'all 0.3s',
                filter: isHovered ? 'drop-shadow(0px 8px 24px rgba(35, 39, 48, 0.15))' : 'none',
            }}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <APStack style={{ minHeight: '124px', maxHeight: '124px' }}>
                <APAlign align={'fill'}>
                    <APImage
                        fit
                        width={'100%'}
                        height={'100%'}
                        style={{ backgroundColor: APPalette['brand-100'], borderRadius: '7px 7px 0px 0px' }}
                        src={imgUrl ?? ''}
                    />
                </APAlign>

                {
                    (isNew || isPopular) &&
                    <APAlign align={'topRight'} noClone>
                        <APColumn gap="8px" crossAxisAlignment="end">
                            {isNew && <Tag label={'New'} />}
                            {isPopular && <Tag label={'Popular'} />}
                        </APColumn>
                    </APAlign>
                }

                {
                    videoId &&
                    <APAlign align='bottomRight' noClone>
                        <>
                            <APRow>
                                <VideoDuration time={duration} />
                                <APSizedBox width='8px' />
                            </APRow>
                            <APSizedBox height='8px' />
                        </>
                    </APAlign>
                }
            </APStack>
            <APColumn
                crossAxisAlignment='stretch'
                mainAxisAlignment='spaceBetween'
                style={{ padding: '8px 12px 12px 12px' }}
                mainAxisSize="max"
            >
                <APColumn crossAxisAlignment='start'>
                    {
                        category && <>
                            <APRow gap="4px">
                                {
                                    categoryImgUrl &&
                                    <APImage height={20} src={categoryImgUrl} />
                                }
                                <APExpanded>
                                    <APText variant={'paragraph-small'} color={APPalette['grey-600']} >
                                        {category}
                                    </APText>
                                </APExpanded>
                            </APRow>
                            <APSizedBox height="4px" />
                        </>
                    }

                    <APText
                        color={APPalette['grey-700']}
                        variant={'h4'}
                        maxLines={2}
                    >
                        {title}
                    </APText>

                    {
                        author &&
                        <>
                            <APSizedBox height="4px" />
                            <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={1} >
                                {author}
                            </APText>
                        </>
                    }
                </APColumn>

                <APColumn >
                    {
                        hashtags &&
                        <APRow
                            style={{ flexWrap: 'wrap' }}
                        >
                            {
                                hashtags.map((item, index) => (
                                    <span
                                        key={index}
                                        className="hashtagClass"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (onHashtagClick) {
                                                onHashtagClick(item)
                                            };
                                        }}
                                        style={{
                                            color: APPalette['info-300'],
                                            fontSize: '12px',
                                            marginRight: '4px',
                                            cursor: onHashtagClick ? 'pointer' : "auto"
                                        }}
                                    >
                                        {`${item}`}
                                    </span>
                                ))
                            }
                        </APRow>
                    }

                    <APSizedBox height="8px" />
                    <APRow style={{ color: 'red' }} mainAxisAlignment='start'>
                        <APExpanded>
                            <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']} >
                                {moment(createdAt).format('DD MMM YYYY')}
                            </APText>
                        </APExpanded>
                        {
                            views > 0 && <APExpanded>
                                <APRow mainAxisAlignment={'end'} gap={'5px'}>
                                    <APImage src="/icons/icon-views.svg" alt="Image" />
                                    <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']}>
                                        {formatNumber(views)}
                                    </APText>
                                </APRow>
                            </APExpanded>
                        }
                    </APRow>
                </APColumn>

            </APColumn>
        </APCard >
    )
}

function MediaCardPoster({ title, createdAt, views, imgUrl, videoId, isNew, isPopular, duration, onClick, webStoryCount }: {
    title: string,
    createdAt: Date,
    views: number;
    imgUrl?: string;
    videoId?: String;
    isNew?: boolean;
    isPopular?: boolean;
    duration?: string
    onClick?: () => void | Promise<void>,
    webStoryCount?: number
}) {
    const [isHovered, setIsHovered] = useState(false);
    const squareImageSize = webStoryCount ? 170 : 180;

    return (
        <APCard
            padding="0"
            style={{
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                filter: isHovered ? 'drop-shadow(0px 8px 24px rgba(35, 39, 48, 0.15))' : 'none',
                width: squareImageSize + 2,
            }}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <APStack style={{
                minHeight: webStoryCount ? 223 : squareImageSize,
                maxHeight: webStoryCount ? 223 : squareImageSize,
                minWidth: squareImageSize,
                maxWidth: squareImageSize,
            }}>
                <APAlign align={'fill'}>
                    <APImage
                        fit
                        width={'100%'}
                        height={'100%'}
                        style={{
                            backgroundColor: APPalette['brand-100'], borderRadius: '7px 7px 0px 0px',
                            backgroundSize: webStoryCount ? "cover" : "contain"
                        }}
                        src={imgUrl ?? ''}
                    />
                </APAlign>
                {
                    !!webStoryCount && <APAlign align="topLeft">
                        <APRow>
                            <WebStoryTile thickness={1.5} currentStory={0} storyCount={webStoryCount} />
                        </APRow>
                    </APAlign>
                }


                {
                    (isNew || isPopular) &&
                    <APAlign align={'topRight'} noClone>
                        <APColumn gap="8px" crossAxisAlignment="end">
                            {isNew && <Tag label={'New'} />}
                            {isPopular && <Tag label={'Popular'} />}
                        </APColumn>
                    </APAlign>
                }
                {
                    videoId !== undefined &&
                    <APAlign align='bottomRight' noClone>

                        <>
                            <APRow>
                                <VideoDuration time={duration} />
                                <APSizedBox width='8px' />
                            </APRow>
                            <APSizedBox height='8px' />
                        </>

                    </APAlign>
                }
            </APStack>
            <APColumn
                crossAxisAlignment='stretch'
                mainAxisSize="max"
                mainAxisAlignment="spaceBetween"
                style={{ padding: '8px 12px 12px' }}
                gap="8px"
            >
                <APRow style={{ height: '40px' }} crossAxisAlignment="start">
                    <APExpanded>
                        <APText color={APPalette['grey-700']} variant={'h4'} maxLines={2} style={{ textAlign: "left" }}>
                            {title}
                        </APText>
                    </APExpanded>
                </APRow>
                <APRow >

                    <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']} >
                        {moment(createdAt).format('DD MMM YYYY')}
                    </APText>

                    {views > 0 && <APExpanded>
                        <APRow mainAxisAlignment={'end'} gap={'5px'}>
                            <APImage src="/icons/icon-views.svg" alt="Image" style={{ maxHeight: "16px", maxWidth: "16px" }} />
                            <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']}>
                                {formatNumber(views)}
                            </APText>
                        </APRow>
                    </APExpanded>}
                </APRow>
            </APColumn>
        </APCard>
    )
}

function MediaCardDefault({ title, createdAt, views, imgUrl, videoId, duration, author, onClick }: {
    title: string,
    createdAt: Date,
    views: number;
    imgUrl?: string;
    videoId?: String;
    author?: string;
    duration?: string
    onClick?: () => void | Promise<void>
}) {

    return (
        <APRow gap="12px" style={{ height: "100px", cursor: onClick ? 'pointer' : 'auto' }} onClick={onClick}>
            <APStack style={{ height: '100px', minWidth: '160px' }}>
                <APAlign align='fill'>
                    <APImage
                        fit
                        width={'100%'}
                        height={'100%'}
                        style={{ backgroundColor: APPalette['brand-100'], }}
                        src={imgUrl ?? ''}
                    />
                </APAlign>
                {
                    videoId &&
                    <APAlign align='bottomRight' noClone>
                        <>
                            <APRow>
                                <VideoDuration time={duration} type={'default'} />
                                <APSizedBox width='8px' />
                            </APRow>
                            <APSizedBox height='8px' />
                        </>
                    </APAlign>
                }
            </APStack>

            <APExpanded>
                <APColumn crossAxisAlignment="stretch" mainAxisAlignment='spaceBetween' mainAxisSize="max" style={{ padding: "4px 0" }}>
                    <APColumn crossAxisAlignment='stretch'>
                        <APText color={APPalette['grey-700']} variant={'paragraph-large'} maxLines={2} >
                            {title}
                        </APText>
                        {
                            author && <>
                                <APSizedBox height="4px" />
                                <APText color={APPalette['grey-600']} variant={'paragraph-small'} maxLines={1} >
                                    {author}
                                </APText>
                            </>
                        }
                    </APColumn>
                    <APColumn>
                        <APRow >
                            <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']} >
                                {moment(createdAt).format('DD MMM YYYY')
                                }
                            </APText>
                            {
                                views > 0 &&
                                <>
                                    <APSizedBox width="8px" />
                                    <APText color={APPalette['grey-500']} variant={'paragraph-xsmall'}>
                                        •
                                    </APText>
                                    <APSizedBox width="8px" />
                                    <APExpanded>
                                        <APRow gap={'5px'}>
                                            <APImage src="/icons/icon-views.svg" alt="Image" />
                                            <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']}>
                                                {formatNumber(views)}
                                            </APText>
                                        </APRow>
                                    </APExpanded>
                                </>
                            }
                        </APRow>
                    </APColumn>
                </APColumn>
            </APExpanded>
        </APRow>
    )
}
function MediaCardEvent({ title, imgUrl, language, location, isRecent, onClick, isOnline, eventCategory, isRegistered, eventDate, onRegisterClick, isUpcoming, onShareClick }: {
    title: string,
    imgUrl?: string;
    videoId?: String;
    isNew?: boolean;
    isPopular?: boolean;
    author?: string;
    category?: APMediaCardCategory;
    duration?: string;
    onClick?: () => void | Promise<void>
    onRegisterClick?: () => any
    categoryImgUrl?: string;
    language?: string;
    location?: string;
    isRecent?: boolean;
    isOnline?: boolean;
    eventCategory?: string;
    isRegistered?: boolean;
    eventDate?: Date;
    isUpcoming?: boolean;
    onShareClick?: () => void | Promise<void>


}) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <APCard
            width="240px"
            height="355px"
            padding="0"
            style={{
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                filter: isHovered ? 'drop-shadow(0px 8px 24px rgba(35, 39, 48, 0.15))' : 'none',
            }}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <APStack style={{ minHeight: '124px', maxHeight: '124px' }}>
                <APAlign align={'fill'}>
                    <APImage
                        fit
                        width={'100%'}
                        height={'100%'}
                        style={{ backgroundColor: APPalette['brand-100'], borderRadius: '7px 7px 0px 0px' }}
                        src={imgUrl ?? ''}
                    />
                </APAlign>

                {
                    (isRecent) &&
                    <APAlign align={'topRight'} noClone>
                        <APColumn gap="8px" crossAxisAlignment="end">
                            {isRecent && <Tag label={'Recent'} />}
                        </APColumn>
                    </APAlign>
                }
                {
                    (isUpcoming) &&
                    <APAlign align={'topRight'} noClone>
                        <APColumn gap="8px" crossAxisAlignment="end">
                            {isUpcoming && <Tag label={'Upcoming'} />}
                        </APColumn>
                    </APAlign>
                }

            </APStack>
            <APColumn
                crossAxisAlignment='stretch'
                mainAxisAlignment='spaceBetween'
                style={{ padding: '8px 12px 12px 12px' }}
                mainAxisSize="max"
            >
                <APColumn crossAxisAlignment='start'>
                    <APSizedBox height="4px" />
                    <APRow style={{ height: '40px' }}>
                        <APText
                            color={APPalette['grey-700']}
                            variant={'h4'}
                            maxLines={2}
                        >
                            {title}
                        </APText>
                    </APRow>
                    <APSizedBox height="8px" />
                    {
                        location !== undefined && isOnline === false && <APRow crossAxisAlignment="center">
                            <APIcon icon={"location"} size={16} color={APPalette["brand-300"]} />
                            <APSizedBox width="4px" />
                            <APText
                                color={APPalette['grey-500']}
                                variant={'paragraph-small'}
                                maxLines={1}
                            >
                                {location}
                            </APText>
                        </APRow>

                    }
                    {
                        isOnline == true && <APRow crossAxisAlignment="center">
                            <APIcon icon={"video"} size={16} color={APPalette["brand-300"]} />
                            <APSizedBox width="4px" />
                            <APText
                                color={APPalette['grey-500']}
                                variant={'paragraph-small'}
                                maxLines={1}
                            >
                                {"Zoom Event"}
                            </APText>
                        </APRow>
                    }
                    <APSizedBox height="8px" />
                    <APRow crossAxisAlignment="center">
                        <APIcon icon={"calendar"} size={16} color={APPalette["brand-300"]} />
                        <APSizedBox width="4px" />

                        <APText
                            color={APPalette['grey-500']}
                            variant={'paragraph-small'}
                            maxLines={1}
                        >
                            {moment(eventDate).format('DD MMM YYYY, hh:mm A, ddd')}
                        </APText>
                    </APRow>
                    <APSizedBox height="8px" />
                    <APRow crossAxisAlignment="center">
                        <APIcon icon={"language02"} size={16} color={APPalette["brand-300"]} />
                        <APSizedBox width="4px" />

                        <APText
                            color={APPalette['grey-500']}
                            variant={'paragraph-small'}
                            maxLines={2}
                        >
                            {language}
                        </APText>
                    </APRow>
                    <APSizedBox height="8px" />
                </APColumn>
                <APTag variant="info" size="small" >
                    {eventCategory ?? ''}
                </APTag>
                <APSizedBox height="12px" />
                <APRow onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                    <APExpanded>
                        <APAsyncButton size="small"
                            onClick={!isRegistered ? onRegisterClick! : undefined}
                            leading={isRegistered ? <APIcon icon={"check"} size={16} color={APPalette.white} /> : undefined}>
                            {isRegistered ? "Registered" : "Register"}
                        </APAsyncButton>
                    </APExpanded>
                    <APSizedBox width="16px" />
                    <APCard style={{ padding: '8px 10px', borderRadius: '8px', cursor: 'pointer' }} onClick={onShareClick}>
                        <APIcon icon={"share02"} size={16} color={APPalette["grey-700"]} />
                    </APCard>
                </APRow>
            </APColumn>
        </APCard >
    )
}



function MediaCardEventChannelPlus({ title, imgUrl, language, isRecent, onClick, eventCategory, eventDate, videoId, duration, views }: {
    title: string,
    imgUrl?: string;
    videoId?: String;
    isNew?: boolean;
    isPopular?: boolean;
    author?: string;
    category?: APMediaCardCategory;
    duration?: string;
    onClick?: () => void | Promise<void>
    onRegisterClick?: () => any
    categoryImgUrl?: string;
    language?: string;
    location?: string;
    isRecent?: boolean;
    isOnline?: boolean;
    eventCategory?: string;
    isRegistered?: boolean;
    eventDate?: Date;
    isUpcoming?: boolean;
    views?: number


}) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <APCard
            padding="0"
            width="240px"
            height="250px"
            style={{
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                filter: isHovered ? 'drop-shadow(0px 8px 24px rgba(35, 39, 48, 0.15))' : 'none',
            }}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <APStack style={{ minHeight: '124px', maxHeight: '124px' }}>
                <APAlign align={'fill'}>
                    <APImage
                        fit
                        width={'100%'}
                        height={'100%'}
                        style={{ backgroundColor: APPalette['brand-100'], borderRadius: '7px 7px 0px 0px', backgroundSize: 'cover' }}
                        src={imgUrl ?? ''}
                    />

                </APAlign>

                {
                    (isRecent) &&
                    <APAlign align={'topRight'} noClone>
                        <APColumn gap="8px" crossAxisAlignment="end">
                            {isRecent && <Tag label={'Recent'} />}
                        </APColumn>
                    </APAlign>
                }

                {
                    videoId &&
                    <APAlign align='bottomRight' noClone>
                        <>
                            <APRow>
                                <VideoDuration time={duration} />
                                <APSizedBox width='8px' />
                            </APRow>
                            <APSizedBox height='8px' />
                        </>
                    </APAlign>
                }


            </APStack>
            <APColumn
                crossAxisAlignment='stretch'
                mainAxisSize="max"
                mainAxisAlignment="spaceBetween"
                style={{ padding: '8px 12px 12px' }}
                gap="8px"
            >
                <APRow style={{ height: '40px' }} crossAxisAlignment="start">
                    <APExpanded>
                        <APText color={APPalette['grey-700']} variant={'h4'} maxLines={2} style={{ textAlign: "left" }}>
                            {title}
                        </APText>
                    </APExpanded>
                </APRow>
                <APRow mainAxisAlignment="spaceBetween">
                    <APRow>
                        <APIcon icon={"language02"} size={16} color={APPalette["brand-300"]} />
                        <APSizedBox width="4px" />

                        <APText
                            color={APPalette['grey-500']}
                            variant={'paragraph-small'}
                            maxLines={2}
                        >
                            {language}
                        </APText>
                    </APRow>
                    {
                        views ? (
                            <APRow mainAxisAlignment="end" >
                                <APSizedBox width="4px" />
                                <APText
                                    color={APPalette['grey-500']}
                                    variant={'paragraph-small'}
                                    maxLines={1}
                                >
                                    {moment(eventDate).format('DD MMM YYYY')}
                                </APText>
                            </APRow>

                        ) : (
                            <APRow mainAxisAlignment="end" >
                                <APIcon icon={"calendar"} size={16} color={APPalette["brand-300"]} />
                                <APSizedBox width="4px" />
                                <APText
                                    color={APPalette['grey-500']}
                                    variant={'paragraph-small'}
                                    maxLines={1}
                                >
                                    {moment(eventDate).format('MMM, YYYY')}
                                </APText>
                            </APRow>
                        )
                    }
                </APRow>
                <APRow>
                    <APColumn crossAxisAlignment="start">
                        <APTag variant="info" size="small" >
                            {eventCategory ?? ''}
                        </APTag>
                    </APColumn>
                    <APColumn>
                        {views && views > 0 &&
                            <APRow mainAxisAlignment={'end'} gap={'5px'}>
                                <APImage src="/icons/icon-views.svg" alt="Image" style={{ maxHeight: "16px", maxWidth: "16px" }} />
                                <APText variant={'paragraph-xsmall'} color={APPalette['grey-500']}>
                                    {formatNumber(views)}
                                </APText>
                            </APRow>
                        }
                    </APColumn>
                </APRow>
            </APColumn>
        </APCard>
    )
}





export function WebStoryTile({ currentStory, storyCount, thickness = 1, variant }: { currentStory: number, storyCount: number, thickness?: number, onChange?(selected: number): void | Promise<void>, variant?: string }) {

    var active = APPalette["brand-300"]
    var inactive = APPalette["grey-300"]
    if (variant === "secondary") {
        active = APPalette.white;
        inactive = APPalette["grey-500"]
    }

    return <APRow style={{
        padding: "8px",
    }}
        mainAxisAlignment="spaceEvenly"
        crossAxisAlignment="center"
        mainAxisSize="max"
        gap="8px"
    >
        {
            NArray(storyCount).map(index =>
                <APExpanded key={`${index}_key`}>
                    <APSizedBox
                        style={{
                            transition: "all .2s ease-out",
                            border: `${thickness}px solid ${index <= currentStory
                                ? active : inactive}`,
                            borderRadius: "8px"
                        }}
                    />
                </APExpanded>
            )}
    </APRow>
}