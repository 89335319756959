import { useEffect, useState } from "react";
import { APRow } from "../layout";
import { APPalette } from "../utils";
import { APImage } from "./APImage";
import { APText } from "./APText";
import { APIcon, SVGIcon } from "../icons/APIcon";
import { APBadge } from "./APBadge";

export interface Item {
    /**
     * title of the APPills tab
     */
    title: string;
    /**
     * value of each APPillsTab
     */
    value: string;
    /**
     * add icon before the title of APPills Tab
     * @deprecated use apIcon attribute instead
     */
    icon?: string;
    apIcon?: SVGIcon;
    /**
     * count of the data that "All" Tab holds
     * 
     * "0" is not shown in UI
     * 
     * @default 0
     */
    count?: number
    /**
     * If the count is greater than maxCount then it will show maxCount+ instead of the number
     */
    maxCount?: number
}

export interface APPillsTabProps {
    onChange: (v: string) => void | Promise<void>;
    items: Item[];
    initialValue?: string;
    /**
     * count of the data that each Tab holds
     */
    count?: number
    hideAll?: boolean
}


/**
 * @author `Abhishek Sinha`
 */

export function APPills({ onChange, initialValue, items, count = 0, hideAll = false }: APPillsTabProps) {
    const [selected, setSelectedItem] = useState<string>(initialValue ? initialValue : hideAll ? items[0].value : "All");

    useEffect(() => {
        onChange(selected)
    }, [selected])

    return (
        <APRow style={{ flexWrap: "wrap" }} gap="16px">
            {
                !hideAll &&
                <Pill
                    onClick={() => setSelectedItem("All")}
                    count={count}
                    title="All"
                    isActive={selected == "All"}
                />
            }
            {
                items.map((e, index) => {
                    return (
                        <Pill
                            key={index}
                            {...e}
                            onClick={() => setSelectedItem(e.value)}
                            isActive={selected.split('/').shift() == e.value}
                            apIcon={e.apIcon}
                        />
                    )
                })
            }
        </APRow>
    )
}

interface PillProps {
    onClick: () => void;
    title: string,
    icon?: string
    apIcon?: SVGIcon;
    count?: number
    isActive: boolean,
    maxCount?: number
}

function Pill({ onClick, isActive, icon, apIcon, title, count, maxCount }: PillProps) {
    const [isHovered, setIsHovered] = useState(false);

    let backgroundColor = APPalette.white;
    if (isHovered) {
        backgroundColor = APPalette['grey-100'];
    }
    if (isActive) {
        backgroundColor = APPalette['brand-300'];
    }

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
            style={{
                border: isActive ? `1px solid ${APPalette['brand-300']}` : `1px solid ${APPalette['grey-200']}`,
                borderRadius: "8px",
                padding: "7px 15px",
                backgroundColor,
                cursor: "pointer",
                transition: "all .3s"
            }}>
            <APRow mainAxisAlignment="center" crossAxisAlignment="center" gap="6px">
                {icon && <APImage src={icon} height="20px" width="20px" />}
                {apIcon && <APIcon icon={apIcon} size={16} color={isActive ? APPalette.white : APPalette['grey-600']} />}
                <APText
                    variant='paragraph-small'
                    style={{ color: isActive ? APPalette.white : APPalette['grey-600'] }}
                >
                    {title}
                </APText>
                {(count != undefined) && <APBadge variant={isActive ? 'secondary' : 'primary'} content={count} max={maxCount} />}
            </APRow>
        </div>
    )
}